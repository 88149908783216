import React from 'react';
import './App.css';

const App = () => {
    return (
        <div className="landing-page">
            <div className="top-image-container">
                <img src="/assets/img/fist_name.png" alt='topImg'/>
            </div>
            <div className="bottom-image-container">
                <img src="/assets/img/fist_header.png" alt='btmImg'/>
            </div>
            <button className="steam-button">
                <img className="steam-icon" src="/assets/img/steam-button.svg" alt="SteamIcon"/>
            </button>
        </div>
    );
}

export default App;
